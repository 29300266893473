<script>
import { PostApiWithAuthorizationAction } from '../../../helpers/apiActions';

export default {
  name: 'AddIdentifiedBulkItemsToBin',
  props: {
    binNumber: {
        type: [ String, Number ],
        required: true
    },
    showActions: {
        type: Boolean,
        required: true,
        default: true
    }
  },
  data() {
    return {
        form: {
            description: ''
        },
        rules: {
            description: [
                { required: true, message: ' ', trigger: 'change' }
            ]
        }
    }
  },
  methods: {
    viewList () {
        this.$root.$emit('display-bin-items-drawer', this.binNumber)
    },
    submitForm() {
        let _vm = this
        _vm.$refs.formContainer.validate((valid) => {
            if (valid) {
                PostApiWithAuthorizationAction("admin/products/identifier/bulk-save", {
                    binNumber: _vm.binNumber,
                    items: _vm.form.description.split(/\r\n|[\r\n]/)
                })
                .then(res => {
                    if (res.status == 'success') {
                        _vm.resetForm()
                    }
                });
            }
        });
    },
    endBin() {
        this.$root.$emit('display-manage-bin-drawer', true)
    },
    resetForm(params = {}) {
        this.form = Object.assign({
            description: ''
        }, params)
    }
  },
  computed: {},
  mounted () {},
  watch: {
    binNumber: function() {
        this.resetForm()
    }
  }
}
</script>

<template>
<div class="px-2" style="overflow: hidden">
    <el-form
        :model="form"
        @submit.native.prevent="submitForm"
        ref="formContainer"
        id="identifierForm">
        <div class="mb-1 pt-1 text-center" v-if="showActions">
            <el-button type="primary" @click="viewList()">
                <i class="fas fa-list-alt"></i> ITEMS LIST
            </el-button>
            <el-button type="danger" @click="endBin()">
                <i class="fas fa-times"></i> END BIN
            </el-button>
        </div>
        <b style="font-size: 25px; color: red;">ITEMS</b>
        <el-form-item
            class="mb-1"
            :rules="rules.description"
            prop="description">
            <label class="mb-0">Description</label>
            <el-input
                type="textarea"
                :rows="12"
                ref="drawerDescriptionField"
                placeholder=""
                autofocus
                v-model="form.description">
            </el-input>
        </el-form-item>
        <div class="mt-2 text-right">
            <el-button
                type="primary"
                native-type="submit"
                :disabled="form.description.length==0">
                SUBMIT
            </el-button>
        </div>
    </el-form>
</div>
</template>
<style>
    #identifierForm .el-form-item__content {
        line-height: 28px;
    }
</style>
