<script>
import {  common } from '@/mixins/common'
import { isMobile, isTablet } from 'mobile-device-detect'
import VueSelectImage from 'vue-select-image'
// add stylesheet
require('vue-select-image/dist/vue-select-image.css')
import {
  PostApiWithAuthorizationAction,
  GetApiActionWithAuthorization
} from "../../../helpers/apiActions"

export default {
  name: 'ShareSocialMedia',
  components: {
    VueSelectImage
  },
  props: {
    product: {
        type: Object,
        required: true
    },
    applications: {
        type: Array,
        required: true
    },
    socialMedias: {
        type: Array,
        required: true
    }
  },
  data() {
    return {
        tab: 'postForm',
        tabs: [
            {
                value: 'Publish Page Post',
                link: 'postForm',
                icon: 'el-icon-s-promotion',
                active: true
            },
            {
                value: 'Page Posts',
                link: 'postList',
                icon: 'el-icon-tickets',
                active: false,
                count: 0
            },
        ],
        mediaPosts: [],
        shareAs: [
            'Text',
            'Link'
        ],
        form: {
            title: '',
            description: '',
            selectedPages: [],
            selectedApp: [],
            selectedPictures: [],
            selectSharedOption: 'Text'
        },
        submitting: false,
        rules: {
            title: [
                { required: true, message: ' ', trigger: 'change' }
            ],
            description: [
                { required: true, message: ' ', trigger: 'change' }
            ],
            selectedApp: [
                { required: true, message: ' ', trigger: 'change' }
            ],
        },
        posts: []
    }
  },
  mixins: [
    common
  ],
  methods: {
    deletePost (id) {
        let _vm = this
        if (confirm('Are you sure that you want delete this post ?')) {
            _vm.$toast.warning('Deleting...', {
                position: "bottom-right",
                timeout: false,
                closeOnClick: true,
                icon: 'el-icon-loading'
            })
            GetApiActionWithAuthorization("admin/social-medias/delete-post/" + id).then(() => {
                _vm.$toast.clear()
                _vm.loadPosts()
            }).catch(error => {
                // eslint-disable-next-line
                console.log(error)
                _vm.$toast.clear()
                _vm.$toast.error(error.message, {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true
                });
            })
        }
    },
    loadPosts () {
      let _vm = this
      return GetApiActionWithAuthorization("admin/social-medias/get-posts/" + this.product.id).then(res => {
        _vm.posts = res.data
      });
    },
    submitForm() {
        let _vm = this
        _vm.submitting = true
        PostApiWithAuthorizationAction("admin/social-medias/publish-data/" + this.product.id, _vm.form).then(res => {
            _vm.submitting = false
            if (res.status === 'success') {
                _vm.loadPosts()
                _vm.$set(_vm.tabs, 1, Object.assign(_vm.tabs[1], {
                    active: true
                }));
                _vm.tab = 'postList'
                _vm.$toast.success('Product successfully Posted.', {
                    position: "top-right",
                    timeout: 3000,
                    closeOnClick: true
                });
            }
        }).catch(error => {
            _vm.submitting = false
          // eslint-disable-next-line
          console.log(error)
          _vm.$toast.error(error.message, {
            position: "top-right",
            timeout: 1000,
            closeOnClick: true
          });
        })
    },
    onSelectImage(e) {
        // eslint-disable-next-line
        console.log(e)
    }
  },
  computed: {
    formApplications () {
        return this.product.ApplicationHasListedAuctions.map(v=>{
            return {
                id: v.appId,
                name: v.Application.name
            }
        })
    },
    manageBinLabelDrawerSize () {
      return isMobile ? '100%' : isTablet ? '50%' : '30%'
    },
    images () {
        let applications = this.formApplications.map(v=>v.id)
        return this.product.AuctionAttachments.filter(v=>applications.includes(v.appId)).map(v=>{
            return {
                id: v.id,
                src: v.thumbnailUrl,
                alt: ''
            }
        })
    }
  },
  mounted () {
    this.form.title = this.product.title
    let description = this.product.parameters.description.replace(/<br>/gi, '\n')
    const div = document.createElement("div")
    div.innerHTML = description
    this.form.description = div.innerText
    this.form.selectedPages = this.socialMedias.length > 0 ? [this.socialMedias[0].id] : []
    this.form.selectedApp = this.formApplications.map(v=>v.id)
    this.form.selectedPictures = (this.images.length > 0) ? [this.images[0]] : []
    //fix auto select pictures issues
    document.querySelectorAll('.vue-select-image__item .vue-select-image__thumbnail')[0].click()
    //
    this.loadPosts()
  },
  watch: {}
}
</script>

<template>
<div class="">
    <el-tabs
        type="border-card"
        v-model="tab"
        stretch>
          <el-tab-pane
            v-for="_tab in tabs"
            :key="_tab.link"
            :active="_tab.active"
            :name="_tab.link"
          >
            <template #label>
                <i :class="['font-size-16', _tab.icon]"></i> {{ _tab.value }}
                <span 
                  v-if="tab.count"
                  class="tab-span-count">{{_tab.count }}</span>
            </template>
            <div class="pt-2" v-if="tab == 'postForm'">
                <el-form
                    :model="form"
                    ref="formPublishContent"
                    @submit.native.prevent="submitForm">
                    <div class="box-section facebook-row-group mb-3">
                        <div class="box-section-header">SOCIAL MEDIA PAGE</div>
                        <div class="box-section-body pt-2 text-center">
                            <el-select
                                v-model="form.selectedPages"
                                placeholder="Social Media Page"
                                size="medium"
                                style="width: 50% !important"
                                multiple
                                >
                                <el-option
                                    v-for="(socialMedia, index) in socialMedias"
                                    :key="index"
                                    :label="socialMedia.name"
                                    :value="socialMedia.id"
                                ></el-option>
                            </el-select>
                        </div>
                    </div>
                    <div class="box-section facebook-row-group">
                        <div class="box-section-header">CONTENT MANAGEMENT</div>
                        <div class="box-section-body pt-2 text-center">
                            <el-row :gutter="10" class="mb-2">
                                <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12">
                                    <el-form-item
                                        class="mb-0"
                                        :rules="rules.selectedApp"
                                        prop="selectedApp">
                                        <el-select
                                            v-model="form.selectedApp"
                                            placeholder="Applications"
                                            size="medium"
                                            multiple
                                            >
                                            <el-option
                                                v-for="(formApplication, index) in formApplications"
                                                :key="index"
                                                :label="formApplication.name"
                                                :value="formApplication.id"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12">
                                    <label style="vertical-align: middle">Share As:&nbsp;&nbsp;</label>
                                    <div
                                        class="btn-group"
                                        role="group"
                                        aria-label="Share Options"
                                        id="share-options">
                                        <el-radio-group v-model="form.selectSharedOption" size="medium">
                                            <el-radio-button label="Text" ></el-radio-button>
                                            <el-radio-button label="Link"></el-radio-button>
                                        </el-radio-group>
                                    </div>
                                </el-col>
                            </el-row>
                            <vue-select-image
                                :dataImages="images"
                                :selectedImages="form.selectedPictures"
                                @onselectimage="onSelectImage">
                            </vue-select-image>
                            <el-form-item
                                class="mb-3"
                                :rules="rules.title"
                                prop="title">
                                <div>
                                    <el-input
                                        placeholder="Enter a title"
                                        v-model="form.title">
                                        <template slot="prepend">Title</template>
                                    </el-input>
                                </div>
                            </el-form-item>
                            <el-form-item
                                class="mb-0"
                                :rules="rules.description"
                                prop="description">
                                <el-input
                                    type="textarea"
                                    :rows="7"
                                    placeholder="Enter a description"
                                    v-model="form.description">
                                </el-input>
                            </el-form-item>
                            <div class="text-right pt-2">
                                <el-button
                                    v-if="!submitting"
                                    type="success"
                                    native-type="submit"
                                    size="small"
                                    ><i class="fas fa-upload"></i> Publish</el-button>
                                <el-button type="success" :loading="true" size="small" v-else>Publishing</el-button>
                            </div>
                        </div>
                    </div>
                </el-form>
            </div>
            <div v-if="tab == 'postList'">
                <div class="text-center">
                    <div v-for="(post, index) in posts" :key="index">
                        <div class="">
                            <iframe
                            :src="'https://www.facebook.com/plugins/post.php?href=' + post.postUrl + '%2F&width=500&show_text=false&appId=1072625959884138&height=274'"
                            width="500"
                            height="480"
                            style="border:none;overflow:hidden"
                            scrolling="no"
                            frameborder="0"
                            allowfullscreen="true"
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                            <div style="float: right">
                                <a :href="post.postUrl" target="_blank">
                                    <el-button
                                    icon="el-icon-view"
                                    type="primary"
                                    size="medium"
                                    circle></el-button>                                
                                </a>&nbsp;
                                <el-button
                                    icon="el-icon-close"
                                    type="danger"
                                    size="medium"
                                    @click="deletePost(post.id)"
                                    circle></el-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-tab-pane>
    </el-tabs>
</div>
</template>
<style scoped>
.b-table {
    background-color: rgba(0, 0, 0, 0.05);
    border: 5px solid #5b5f70;
}
</style>
<style>
.BinManage_content .el-form-item__error {
    color: white;
    padding: 8px;
    background: red;
    border-radius: 5px;
    margin: 4px;
    display: block;
    position: relative !important;
    text-align: center
}
</style>